<template>
	<errorContainer :error="erreur" :warning="warning">
		<transition name="slide">
			<div
				:class="{
					'container-layout': $screen.width >= 992,
					'container-mobile': $screen.width < 992
				}"
				class="p-0"
			>
				<b-row class="form">
					<b-col cols="12">
						<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, , { 'is-pwa': $isPwa() }]">
							{{ FormMSG(235, 'Imports') }}
						</h1>
					</b-col>
					<b-card class="w-100">
						<b-row>
							<b-col sm="12" md="12" lg="6" xl="6">
								<b-card>
									<b-form-group :label="FormMSG(10, 'Import type:')" label-for="importType" :label-cols="4">
										<b-form-select
											v-model="importType"
											:options="isDev ? optionsForDevImports : optionsForImports"
											size="md"
											id="exportType"
										/>
									</b-form-group>
									<b-card v-if="showMovieMagicFile" class="w-100">
										<b-form-group :label="FormMSG(5020, 'File to import')" label-for="accConfirmationFileBrowse" :label-cols="12">
											<b-form-file
												v-model="pdfFile"
												:browse-text="FormMSG(113, 'Browse')"
												name="test"
												v-if="importType === 2"
												id="accConfirmationFileBrowse"
												accept=".xlsx"
												ref="pdfConfirmationFileId"
												@change="submitConfirmationFile"
												:placeholder="FormMSG(51, 'Choose a file or drop it here...')"
												:drop-placeholder="FormMSG(52, 'Drop file here...')"
											>
											</b-form-file>
											<b-form-file
												v-model="pdfFile"
												:browse-text="FormMSG(113, 'Browse')"
												name="test"
												v-else
												id="accConfirmationFileBrowse"
												accept=".xml"
												ref="pdfConfirmationFileId"
												@change="submitConfirmationFile"
												:placeholder="FormMSG(51, 'Choose a file or drop it here...')"
												:drop-placeholder="FormMSG(52, 'Drop file here...')"
											>
											</b-form-file>
										</b-form-group>
										<b-form-group :label="FormMSG(5021, 'Filename')" label-for="docFileName" :label-cols="12">
											<b-form-input v-model="fileName" :disabled="true" type="text" id="docFileName"></b-form-input>
										</b-form-group>
										<b-col xl="12" v-if="!isDev || (isDev && importType <= 2) || importType === 7">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll" v-model="eraseDepartmentBeforeImport">
													{{ this.FormMSG(5023, 'Erase all unused departments and functions before import') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
										<b-col xl="12" v-if="!isDev || (isDev && importType <= 2) || importType === 7">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll-clean" v-model="eraseCategoryBeforeImport">
													{{ this.FormMSG(65, 'Cleaning categories') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
										<b-col xl="12" v-if="!isDev || (isDev && importType <= 2) || importType === 7">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll1" v-model="importEmptyAccountDetails">
													{{ this.FormMSG(5024, 'Import empty detail account') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
										<b-col xl="12" v-if="!isDev || (isDev && importType < 2) || importType === 7">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll2" v-model="accountDetailsWith3NumbersAsGroupingCategory">
													{{ this.FormMSG(5025, 'Import account detail with 3 number as grouping category') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
										<b-col xl="12" v-if="!isDev || (isDev && importType <= 2) || importType === 7">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll3" v-model="createDepartmentsAndFunctions">
													{{ this.FormMSG(5026, 'Create departments and functions') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
										<b-col xl="12" class="mt-3" v-if="createDepartmentsAndFunctions">
											<b-row class="lh-20">
												<b-col>
													<b-form-checkbox size="md" id="disAll4" v-model="specifyCategoriesToCreate" v-if="importType < 2">
														{{ this.FormMSG(5027, 'Create functions only for the following Movie Magic categories') }}
													</b-form-checkbox>
													<b-form-checkbox size="md" id="disAll4" v-model="specifyCategoriesToCreate" v-else>
														{{ this.FormMSG(5029, 'Create functions only for the following Xotis categories') }}
													</b-form-checkbox>
												</b-col>
												<b-col>
													<v-select
														:disabled="!specifyCategoriesToCreate"
														v-model="listOfCategoriesToCreateFunctionForAccountDetails"
														:options="categorieList"
														:taggable="true"
														:multiple="true"
														push-tags
														@search="
															(search, loading) => {
																test(search);
															}
														"
													>
													</v-select>
													<small style="">{{ FormMSG(50555, 'Press enter to validate a value') }}</small>
													{{ listOfCategoriesToCreateFunctionForAccountDetails.toString() }}
												</b-col>
											</b-row>
										</b-col>
									</b-card>
									<b-card v-if="showXLSFile">
										<b-form-group :label="FormMSG(5020, 'File to import')" label-for="accConfirmationFileBrowse" :label-cols="12">
											<b-form-file
												v-model="pdfFile"
												:browse-text="FormMSG(113, 'Browse')"
												name="test"
												id="accConfirmationFileBrowse"
												accept=".xlsx"
												ref="pdfConfirmationFileId"
												@change="submitConfirmationFile"
												:placeholder="FormMSG(51, 'Choose a file or drop it here...')"
												:drop-placeholder="FormMSG(52, 'Drop file here...')"
											>
											</b-form-file>
										</b-form-group>
										<b-form-group :label="FormMSG(5021, 'Filename')" label-for="docFileName" :label-cols="12">
											<b-form-input v-model="fileName" :disabled="true" type="text" id="docFileName"></b-form-input>
										</b-form-group>
										<b-col xl="12" v-if="!isDev || (isDev && importType <= 2) || importType === 7 || importType === 8">
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll" v-model="eraseDepartmentBeforeImport">
													{{ this.FormMSG(5023, 'Erase all unused departments and functions before import') }}
												</b-form-checkbox>
											</b-row>
											<b-row class="lh-20">
												<b-form-checkbox size="md" id="disAll-clean" v-model="eraseCategoryBeforeImport">
													{{ this.FormMSG(65, 'Cleaning categories') }}
												</b-form-checkbox>
											</b-row>
										</b-col>
									</b-card>
									<b-card v-if="showDates">
										<b-form-group v-if="this.initDates" :label="FormMSG(11, 'From:')" label-for="from" :label-cols="4">
											<b-form-input v-model="from" type="date" id="from"></b-form-input>
										</b-form-group>
										<b-form-group :label="FormMSG(12, 'To:')" label-for="to" :label-cols="4">
											<b-form-input v-model="to" type="date" id="to"></b-form-input>
										</b-form-group>
									</b-card>
									<div class="d-flex justify-content-end">
										<b-row>
											<b-col sm="12">
												<b-button variant="primary" v-on:click="processImport" :disabled="isLoading">
													{{ this.FormMSG(20, 'Start import process') }}
													<span class="ml-2" v-if="isLoading">
														<b-spinner style="width: 1.25rem; height: 1.25rem" variant="warning" label="loading ..."></b-spinner>
													</span>
												</b-button>
											</b-col>
										</b-row>
									</div>
								</b-card>
							</b-col>
						</b-row>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(60, 'Success!')"
							class="modal-success"
							v-model="successModal"
							@ok="successModal = false"
							ok-variant="success"
							ok-only
							modal-class="mui-animation"
							:fade="false"
						>
							{{ this.FormMSG(61, 'Your data has been imported with success.') }}
						</b-modal>
						<b-modal
							header-class="header-class-modal-doc-package"
							:title="FormMSG(62, 'Error!')"
							class="modal-error"
							v-model="errorModal"
							@ok="errorModal = false"
							ok-variant="success"
							ok-only
							modal-class="mui-animation"
							:fade="false"
						>
							{{ this.errorMessage }}
						</b-modal>
						<b-modal
							header-class="header-class-modal-doc-package"
							v-model="openLog"
							size="lg"
							ok-only
							ok-title-html
							:title="FormMSG(64, 'Log')"
							:ok-title="FormMSG(63, 'Close')"
							@ok="handleCloseLogWindow"
							@cancel="handleCloseLogWindow"
							@hidden="handleCloseLogWindow"
							no-close-on-backdrop
							modal-class="mui-animation"
							:fade="false"
						>
							<b-card no-body>
								<b-card-text v-html="importLog" class="px-3 pb-3" />
							</b-card>
						</b-modal>
						<!-- <loading :active.sync="isLoading" :is-full-page="true"></loading> -->
						<script-export-modal
							v-model="isScriptReportOpen"
							:items="scripts"
							:custom="customReport"
							:export-type="exportTypeStr"
							@script-export-modal:loading="handleScriptLoading"
							@script-export-modal:closed="handleScriptRerpotOnClosed"
						/>
					</b-card>
				</b-row>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import { store } from '@/store';
import { isNil } from '@/shared/utils';
import axios from 'axios';
// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import languageMessages from '@/mixins/languageMessages';
import { generateRandomFilename } from '~utils';
import { getScripts } from '@/cruds/script.crud';
import ScriptExportModal from '@/components/Export/ScriptExportModal';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'Imports',
	mixins: [languageMessages, globalMixin],
	components: {
		Loading,
		ScriptExportModal
	},
	data: () => {
		return {
			from: '',
			to: '',
			importType: 0,
			warning: '',
			erreur: {},
			isLoading: false,
			docFil: {},
			pdfFile: null,
			fileName: '',
			successModal: false,
			errorModal: false,
			errorMessage: '',
			eraseDepartmentBeforeImport: false,
			apiImportUrl: 'uploadImportFormatFile',
			openLog: false,
			importLog: '',
			importEmptyAccountDetails: false,
			accountDetailsWith3NumbersAsGroupingCategory: false,
			createDepartmentsAndFunctions: false,
			eraseCategoryBeforeImport: false,
			specifyCategoriesToCreate: false,
			InvalidExp: false,
			listOfCategoriesToCreateFunctionForAccountDetails: [],
			categorieList: [],
			scripts: [],
			customReport: null,
			scriptUserId: 13,
			isScriptReportOpen: false,
			exportTypeStr: ''
		};
	},
	computed: {
		showMovieMagicFile() {
			let ret = true;
			switch (this.importType) {
				case 0:
					// import moviemagic budget
					break;
				case 2:
					// import Xotis excell file
					break;
				default:
					ret = false;
					break;
			}
			return ret;
		},
		showXLSFile() {
			var ret = true;
			switch (this.importType) {
				case 1:
					// import CNC Annex 1 and 3 excell file
					break;
				case 3:
					// import users file
					break;
				case 4:
					// import transports file
					break;
				case 5:
					// import suppliers file
					break;
				case 7:
					break;
				case 8:
					// import ccn 2642
					break;
				default:
					ret = false;
					break;
			}
			return ret;
		},
		showDates() {
			var ret = true;
			switch (this.importType) {
				case 0:
					// import moviemagic budget
					ret = false;
					break;
				case 1:
					// CNC ANNEX 1;
					ret = false;
					break;
				case 2:
					// CNC ANNEX 2;
					ret = false;
					break;
				case 3:
					// CNC ANNEX 3;
					// import users file ---- NOW
					ret = false;
					break;
				case 4:
					// import transports file
					ret = false;
					break;
				case 5:
					// import suppliers file
					ret = false;
					break;
				case 7:
					// Import Ooviiz projects
					ret = false;
					break;
				case 8:
					// Import ccn 2642
					ret = false;
					break;
				default:
					break;
			}
			return ret;
		},
		optionsForImports() {
			let options = this.FormMenu(1116);

			options.sort((a, b) => {
				if (a.text.toLowerCase() < b.text.toLowerCase()) {
					return -1;
				}
				if (a.text.toLowerCase() > b.text.toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return options;
		},
		initDates() {
			if (this.from.length === 0) {
				var currentDateWithFormat = new Date().toJSON().slice(0, 10);
				this.from = currentDateWithFormat.substring(0, 8) + '01';
				this.to = currentDateWithFormat;
			}
			return true;
		},
		isDev() {
			return store.isDev();
		},
		optionsForDevImports() {
			let options = this.FormMenu(1326);

			options.sort((a, b) => {
				if (a.text.toLowerCase() < b.text.toLowerCase()) {
					return -1;
				}
				if (a.text.toLowerCase() > b.text.toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return options;
		}
	},
	methods: {
		sendDates() {
			let ret = true;
			switch (this.importType) {
				case 0:
					// import moviemagic budget
					ret = false;
					break;
				case 1:
					// "import annex 1";
					ret = false;
					break;
				case 2:
					// "import annex 2";
					ret = false;
					break;
				case 3:
					// "import annex 3";
					ret = false;
					break;
				case 7:
					// import Ooviiz;
					ret = false;
					break;
				case 8:
					// import ccn 2642;
					ret = false;
					break;
				default:
					break;
			}
			return ret;
		},
		test(val) {
			let reg = /^\d+$/;
			let res = reg.test(val);
			this.InvalidExp = res;
			// console.log(res);
		},
		getImportLinkName() {
			//console.log("this.exportType:",this.exportType);
			let ret = 'undefined';

			switch (this.importType) {
				case 0:
					ret = 'uploadBudgetFile';
					//ret = "uploadMovieMagicBudget";
					break;
				case 1:
					ret = 'uploadBudgetFile';
					//ret = "uploadCNCAnnex";
					break;
				case 2:
					ret = 'uploadBudgetFile';
					break;
				case 7:
					ret = 'importOoviizFile';
					break;
				case 8:
					ret = 'uploadBudgetFile';
					break;
				default:
					break;
			}

			return ret;
		},
		submitConfirmationFile(ev) {
			if (ev.type == 'drop') {
				var docFil = ev.dataTransfer.files[0];
			} else if (ev.type == 'change') {
				var docFil = ev.target.files[0];
			}
			this.docFil = docFil;
			this.fileName = docFil.name;
			// console.log('in submitConfirmationFile, this.docFil:', this.docFil);
		},
		async processImport() {
			//console.log("submitConfirmationFile submit file:", docFil);
			// process import only if file selected successfully
			if (isNil(this.docFil.name) || this.docFil.name.length == 0) {
				alert(this.FormMSG(121, 'You must select a file first'));
				return;
			}

			this.isLoading = true;
			// alert(this.eraseCategoryBeforeImport);
			if (!this.isDev || (this.isDev && this.importType <= 2) || (this.isDev && this.importType === 8)) {
				const fromDate = this.from + 'T00:00:00Z';
				const toDate = this.to + 'T00:00:00Z';

				let formData = new FormData();

				if (this.importType === 7) {
					formData.append('importFile', this.docFil);
				} else {
					formData.append('uploadimage', this.docFil);
				}

				formData.append('fileName', this.docFil.name);
				formData.append('fileType', this.docFil.type);
				formData.append('importFileType', this.importType);

				let val = 0;
				let val2 = 0;
				if (this.eraseDepartmentBeforeImport) {
					val = 1;
				}
				if (this.eraseCategoryBeforeImport) {
					val2 = 1;
				}
				let impEmpty = 0;
				if (this.importEmptyAccountDetails) {
					impEmpty = 1;
				}

				let imp3Num = 0;
				if (this.accountDetailsWith3NumbersAsGroupingCategory) {
					imp3Num = 1;
				}

				let createDepAndFunc = 0;
				if (this.createDepartmentsAndFunctions) {
					createDepAndFunc = 1;
				}

				formData.append('eraseDepartmentBeforeImport', val);
				formData.append('eraseCategoryBeforeImport', val2);
				formData.append('importEmptyAccountDetails', impEmpty);
				formData.append('accountDetailsWith3NumbersAsGroupingCategory', imp3Num);
				formData.append('createDepartmentsAndFunctions', createDepAndFunc);
				formData.append('listOfCategoriesToCreateFunctionForAccountDetails', this.listOfCategoriesToCreateFunctionForAccountDetails.toString());

				if (this.importType > 0 && this.importType < 4) {
					formData.append('annexNumber', this.importType);
				}

				const servicePath = this.getImportLinkName();
				let urlStr = '';
				// console.log(formData);
				if (this.sendDates()) {
					urlStr = process.env.VUE_APP_GQL + '/' + servicePath + '/?From=' + fromDate + '&To=' + toDate + '&Email=0';
				} else {
					urlStr = process.env.VUE_APP_GQL + '/' + servicePath;
				}

				// console.log('in processImport, urlStr:', urlStr);
				// console.log('in processImport, formData:', formData);
				// var axiosTmp = require('axios').create();

				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((result) => {
						// console.log("processImport  result:", result);
						if (result.data == 'OK') {
							// console.log("processImport success result:", result.data);
							this.isLoading = false;
							this.successModal = true;
						} else {
							this.errorMessage = result.data;
							this.errorModal = true;
						}
					})
					.catch((error) => {
						this.isLoading = false;
						this.erreur = error;
					})
					.finally(() => (this.isLoading = false));
			} else {
				let formData = new FormData();

				if (this.importType === 7) {
					this.apiImportUrl = 'importOoviizFile';

					let val = 0;
					let val2 = 0;

					if (this.eraseDepartmentBeforeImport) {
						val = 1;
					}

					if (this.eraseCategoryBeforeImport) {
						val2 = 1;
					}

					formData.append('eraseDepartmentBeforeImport', val);
					formData.append('eraseCategoryBeforeImport', val2);
				}

				formData.append('importFile', this.docFil);
				formData.append('fileName', this.docFil.name);
				formData.append('fileType', this.docFil.type);
				formData.append('importFormatType', this.importType);
				const urlStr = process.env.VUE_APP_GQL + '/' + this.apiImportUrl;

				if (this.importType === 3) {
					this.exportTypeStr = this.EXPORTNAME.USER;
					this.scripts = await getScripts(store.state.myProject.licenseID, store.state.myProject.id, this.scriptUserId, true);

					if (this.scripts.length === 1) {
						formData.append('scriptId', this.scripts[0].id);
					} else if (this.scripts.length > 1) {
						this.isLoading = false;
						this.customReport = {
							formData
						};

						this.isScriptReportOpen = true;

						return;
					}
				}

				axios
					.post(urlStr, formData, {
						headers: {
							Authorization: `Bearer ${store.state.token}`,
							'Content-Type': 'multipart/form-data'
						}
					})
					.then((record) => {
						// console.log({data: record.headers['content-type']});
						// console.log({dbExtension});
						this.$toast({
							message: this.FormMSG(61, 'Your data has been imported with success.'),
							title: this.FormMSG(60, 'Success!'),
							variant: 'success'
						});
						this.importLog = record.data.replace(/[\n]+/g, '<br />');
						this.pdfFile = null;
						this.docFil = null;
						this.fileName = '';
						this.openLog = true;
						// force download
						const url = window.URL.createObjectURL(new Blob([record.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', `${generateRandomFilename()}.txt`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					})
					.catch((error) => {
						this.isLoading = false;
						this.erreur = error;
					})
					.finally(() => {
						this.isLoading = false;
					});
			}
		},
		handleCloseLogWindow() {
			this.openLog = false;
		},
		handleScriptRerpotOnClosed() {
			this.isScriptReportOpen = false;
			this.isLoading = false;
			this.pdfFile = null;
			this.docFil = null;
			this.fileName = '';
		},
		handleScriptLoading(payload) {
			if (payload) {
				this.isLoading = false;
			}
		}
	}
};
</script>
